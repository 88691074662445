import axios from "axios";
import { J_TOKEN } from "../utils/Var";

const instance = axios.create({
    timeout: 10000,
    baseURL: (process.env.VUE_APP_TASK_LINK || "http://test2.tuzuu.com") + "/api/v1",
});

// 请求拦截器
instance.interceptors.request.use(request => {
    const token = localStorage.getItem(J_TOKEN);
    if (token){
        request.headers.Authorization = 'Bearer ' + token;
    } 
    return request;
}, err => {
    return Promise.reject(err)
})

// 响应拦截器
instance.interceptors.response.use(res => {
    if (res.status === 200) {
        return res.data;
    }
    return res;
}, err => {
    return Promise.reject(err);
});

export default instance;