
import ServicePopup from "@/components/ServicePopup";
import posters from "@/components/posters"
import taskAxios from "@/http/task";
import Comments from "@/components/Comments"
import Coupon from "@/components/Coupon/Coupon"
import wxShare from "@/wechat/weChatTile";

export default {
    data() {
        return {
            active: 0,
            data: {
                goods: [{
                    comment_num: 0,
                    price_show: '',
                    price: '0.00',
                    buy_num: 0,
                    title: '',
                    price_pin: '',
                    pin_end_time: '',
                    kf_info: {
                        kf_qrcode: '',
                    },
                },],
                goods_pin: [{
                    users_headimgurl: ''
                }],
                coupon: []
            },
            kefuShow: false,
            selectShow: false, // 套餐选择
            selectPackge: {},
            timer: '',
            showPoster: false, //分享海报
            posters: {},
            status: 0, //1是秒杀，2是抢购
            spellList: false, //控制拼单
            commentList: [], //评论列表
            coupon: false,
            couponTime: true
        }
    },
    components: {
        ServicePopup,
        posters,
        Comments,
        Coupon
    },
    created() {
        this.getComments()
        const query = this.$route.query;
        if (query.task_userid && query.taskid && query.scan_time) {
            this.taskTimer = setTimeout(() => {
                taskAxios.post("/done_view_level_task", {
                    task_userid: query.task_userid,
                    task_id: query.taskid
                }).then(res => {
                    this.$toast("任务完成");
                    this.$router.replace({
                        path: this.$route.path
                    })
                })
            }, query.scan_time * 1000);
        }
    },
    computed: {
        //判断活动有没有结束
        isTimeOut() {
            let arr1 = this.data.goods[0].pin_end_time.slice(0, 10).split("-")
            let arr2 = this.data.goods[0].pin_end_time.slice(11).split(":")
            let nowDate = new Date().getTime()
            let endDate = new Date(arr1[0], parseInt(arr1[1]) - 1, arr1[2], arr2[0], arr2[1], arr2[2]).getTime()
            return endDate - nowDate
        },
    },
    beforeRouteLeave(to, from, next) {
        clearTimeout(this.taskTimer)
        next();
    },
    mounted() {
        this.share()
        const query = this.$route.query
        //用户页面停留倒计时
        //设置轮播图高度


        if (query.scan_time && query.task_id) {
            this.timer = setTimeout(() => {
                axios.get("/done_view_goods", {
                    params: {
                        task_id: query.task_id,
                        scan_time: query.scan_time
                    }
                }).then(res => {
                    console.log(res)
                })
            }, query.scan_time * 1000);
        }
        if (query.userid && query.task_id) {
            axios.get("/done_click_task", {
                params: {
                    task_id: query.task_id,
                    task_userid: query.userid
                }
            }).then(res => {
                console.log(res);
            })
        }
    },
    methods: {
        //解决初始化问题
        initHeight() {
            let bannerHeight = document.querySelector(".van-image__img")
            let video = document.querySelector(".van-swipe")
            video.style.height = bannerHeight.offsetHeight + 'px'
        },
        //领取优惠券
        getCoupons(item) {
            if (this.couponTime) {
                this.couponTime = false
                axios.get(
                    "recive_coupon", {
                        params: {
                            coupon_id: item.id
                        }
                    }
                ).then(res => {
                    this.couponTime = true
                    if (res.code == 1) {
                        this.$toast('领取成功')
                    } else {
                        this.$toast(res.message)
                        item.is_rec = 1
                    }

                })
            }
        },
        more() {
            let id = this.$route.params.id
            this.$router.push({
                path: `/comment/${id}`
            })
        },
        //获取评论
        getComments() {
            axios.get(
                "get_comment", {
                    params: {
                        goods_id: this.$route.params.id,
                    }
                }
            ).then(res => {
                this.commentList = res.data.slice(0, 5)
            })
        },
        initVideo() {
            setTimeout(() => {
                this.$video(myVideo, {
                    //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
                    controls: true,
                    //自动播放属性,muted:静音播放
                    autoplay: "muted",
                    //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
                    preload: "auto",
                    //设置视频播放器的显示宽度（以像素为单位）
                    width: "800px",
                    //设置视频播放器的显示高度（以像素为单位）
                    height: "400px"
                });
            }, 1000);
        },
        openSpellList() {
            this.spellList = true
        },
        openPoster() {
            console.log(this.posters.backUrl.length)
            if (this.posters.backUrl[0] == '') {
                this.$toast('暂无海报')
            } else {
                this.showPoster = true
            }
        },
        // 海报
        downPosters() {
            this.showPoster = false
        },
        //关闭遮罩
        cloneShow() {
            let url = this.$route.params.id
            if (url != this.selectPackge.id) {
                window._czc.push(['_trackEvent', '点击商品', '商品id', , this.selectPackge.id])
                window.location.href = '/detail/' + this.selectPackge.id
            }
        },
        // 选择其他套餐
        selectOtherPackges(item) {
            this.selectPackge = item
        },
        //显示选择弹窗
        openSelect() {
            // this.codeStatus = codeStatus
            this.selectShow = true
        },
        //开启优惠券
        openCoupon() {
            this.coupon = true
        },
        backHome() {
            this.$router.push({
                path: '/'
            })
        },
        //发起拼单
        oneBackOrder(codeStatus = 0) {
            let goods_id = this.selectPackge.id
            window._czc.push(['_trackEvent', '商品详情', '立即预定id', , goods_id])

            this.$router.push({
                path: `/place_order/${goods_id}`,
                query: {
                    mid: this.$route.query.mid || '',
                    task_id: this.$route.query.task_id || '',
                    userid: this.$route.query.userid || '',
                    is_express: this.data.goods[0].is_express || 0,
                    skill: 1, //1代表秒杀或者抢购，0是正常
                    groupid: 0, //发起拼单为0
                    preson: this.$route.query.preson || '' //身份，分享师行长
                }
            })
        },

        backOrder() {
            let goods_id = this.selectPackge.id

            window._czc.push(['_trackEvent', '商品详情', '立即预定id', , goods_id])

            this.$router.push({
                path: `/place_order/${goods_id}`,
                query: {
                    mid: this.$route.query.mid || '',
                    task_id: this.$route.query.task_id || '',
                    userid: this.$route.query.userid || '',
                    is_express: this.data.goods[0].is_express || 0,
                    preson: this.$route.query.preson || '' //身份，分享师行长
                }
            })
        },
        // 拼团
        groupOrder() {
            let goods_id = this.selectPackge.id
            window._czc.push(['_trackEvent', '商品详情', '发起拼单', goods_id])
            this.$router.push({
                path: `/place_order/${goods_id}`,
                query: {
                    groupid: this.data.goods_pin.length > 0 ? this.data.goods_pin[0].founder_id : '',
                    mid: this.$route.query.mid || '',
                    task_id: this.$route.query.task_id || '',
                    userid: this.$route.query.userid || '',
                    is_express: this.data.goods[0].is_express || 0,
                    skill: 1,
                    preson: this.$route.query.preson || '' //身份，分享师行长
                }
            })
        },
        openKefu() {
            this.kefuShow = true
        },

        async share() {
            let detailRes = await axios.get("/goods_detail", {
                params: {
                    goods_id: this.$route.params.id,
                    mid: this.$route.query.mid,
                    role: this.$route.query.preson
                }
            })
            this.posters = {
                presonImage: detailRes.data.poster[0].headimgurl,
                presonName: detailRes.data.poster[0].nickname,
                info: detailRes.data.poster[0].poster_desc,
                href: window.location.href,
                shut: 'https://khome2.tuzuu.com/klfront/close.png',
                backUrl: detailRes.data.poster[0].poster
            } //海报
            this.data = detailRes.data;
            this.selectPackge = detailRes.data.goods[0]
            this.status = detailRes.data.goods[0].activity_type
            let userid = localStorage.getItem("userid")
            let channel_id = localStorage.getItem("channel_id")
            wxShare({
                title: detailRes.data.goods[0].title,
                desc: detailRes.data.goods[0].share_title,
                link: window.location.href.split('?')[0] + `?userid=${userid}&channel_id=${channel_id}`,
                imgUrl: detailRes.data.goods[0].thumb_pic,
            })
        }
    },
    filters: {
        timer(value) {
            // "2021-02-06 00:00:00"
            let arr1 = value.slice(0, 10).split("-")
            let arr2 = value.slice(11).split(":")
            let nowDate = new Date().getTime()
            let endDate = new Date(arr1[0], parseInt(arr1[1]) - 1, arr1[2], arr2[0], arr2[1], arr2[2]).getTime()
            return (endDate - nowDate)
        }
    },
    beforeDestroy() {
        clearTimeout(this.timer);
    },
};
