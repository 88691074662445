<template>
	<ul class="comments">
		<li v-for="(item,index) in list" :key="index">
			<div class="top">
				<div class="info">
					<van-image :src="item.headimgurl" width="33" height="33" round />
					<span style="margin-left: 5px;">{{item.nickname}}</span>
				</div>
				<div class="timer">{{item.created_at}}</div>
			</div>
			<div class="bottom">
				{{item.comment}}
			</div>
		</li>
	</ul>
</template>

<script>
	export default {
		props:['list']
	}
</script>

<style scoped lang="less">
	.comments{
		padding: 0px 15px;
		box-sizing: border-box;
		margin-top: 15px;
		li{
			margin-bottom: 15px;
			border-bottom: 1px solid #EEEEEE;
			padding-bottom: 15px;
			.top{
				display: flex;
				align-items: center;
				justify-content: space-between;
				.info{
					display: flex;
					font-size: 13px;
					color: #232323;
				}
				.timer{
					font-size: 12px;
					color: #666666;
				}
			}
			.bottom{
				font-size: 14px;
				margin-top: 10px;
				color: #232323;
				letter-spacing: 1px;
				font-family: PingFang-SC-Medium, PingFang-SC;
			}
		}
	}
</style>
