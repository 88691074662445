<template>
	<div class="coupon" :class="{'not-use':opacity}">
		<div class="coupon-left">
			<div class="round"></div>
			<div class="round" v-if="!istag"></div>
			<div class="money" >
				<span v-if="data.sale_off_type == 2">¥</span>
				<strong>{{data.sale_off}}</strong>
				<span style="margin-left: 5px;" v-if="data.sale_off_type == 1">折</span>
			</div>
			<div class="tag">
				{{data.coupon_text}}
			</div>
	<!-- 		<div class="tag2" v-if="data.sale_off_limit > 0">
				满{{data.used_condition}}可用
			</div> -->
		</div>
		<div class="coupon-right">
			<div class="info-title">{{data.title}}</div>
			<div class="info-con" style="margin-bottom: 7px;" v-if="data.limit_use == 0">
				不限制商品 
			</div>
			<div class="info-con" style="margin-bottom: 7px;" v-if="data.limit_use == 1">
				部分商品可用
			</div>
			<div class="info-con" style="margin-bottom: 7px;" v-if="data.limit_use == 2">
				部分商品不可用
			</div>
			
			<div class="info-con" v-if="data.expire_type == 1 || !data.expire_type">{{data.start_time}}至</div>
			<div class="info-con" v-if="data.expire_type == 1 || !data.expire_type">{{data.end_time}}</div>
			
			<div class="info-con" v-if="data.expire_type == 2">领取后立即生效，有效期{{data.expire}}天</div>
			<div class="info-con" v-if="data.expire_type == 3">领取后次日生效，有效期{{data.expire}}天</div>

			<slot name="right" v-if="isRight">
				<!-- 点击领取 -->
				<div class="state1" v-if="state == 1" @click="handleClick">
					{{txt}}
				</div>
				
				<!-- 已使用 -->
				<img src="/img/use.png" class="state2" v-if="state == 2"/>
				<!-- 已过期 -->
				<img src="/img/use3.png" class="state2" v-if="state == 3"/>
				<!-- 已领取 -->
				<img src="/img/use2.png" class="state2" v-if="state == 4"/>
			</slot>
		</div>
		<p class="instr" v-if="istag">规则说明：{{reason}}</p>
	</div>
</template>

<script>
	export default {
		props:{
			isRight:{   //是否显示右边的图片，默认显示
				type:Boolean,
				default:true
			}, 
			istag:{   //是否显示最下面的提示，默认不显示
				type:Boolean,
				default:false
			},
			reason:{   //最下面提示的文字
				type:String,
				default:'结算商品不在该优惠券使用范围内'
			},
			txt:{   //按钮文字
				type:String,
				default:'点击领取'
			},
			data:{
				type:Object
			},
			opacity:{   //是否透明
				type:Boolean,
				default:false
			},
			state:{   //状态，1可使用，2已使用，3已过期，4已领取
				type:Number,
				default:0
			}
		},
		methods:{
			//点击领取时，触发事件
			handleClick(){
				this.$emit("click")
			}
		}
	}
</script>

<style scoped lang="less">
	.coupon{
		width: 100%;
		background-color: white;
		overflow: hidden;
		margin-bottom: 15px;
		.coupon-left{
			width: 29%;
			height: 120px;
			background: linear-gradient(37deg, #00C996 0%, #00B592 100%);
			position: relative;
			float: left;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.money{
				width: 100%;
				text-align: center;
				color: white;
				font-size: 20px;
				margin-bottom: 10px;
				strong{
					font-size: 24px;
					margin-left: 5px;
				}
			}
			.tag{
				color: white;
				font-size: 13px;
				text-align: center;
				width: 100%;
				padding: 0px 5px;
				box-sizing: border-box;
			}
			// .tag2{
			// 	color: white;
			// 	font-size: 12px;
			// 	text-align: center;
			// 	width: 100%;
			// 	white-space:nowrap
			// }
			.round{
				&:nth-of-type(1){
					left: calc(100% - 10px);
					top: -10px;
				}
				&:nth-of-type(2){
					left: calc(100% - 10px);
					bottom: -10px;
				}
			}
		}
		.round{
			width: 20px;
			height: 20px;
			background-color: #F6F6F6;
			border-radius: 50%;
			position: absolute;
		}
		.coupon-right{
			float: left;
			width: 71%;
			height: 120px;
			padding-left: 10px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			font-size: 13px;
			position: relative;
			justify-content: center;
			.info-title{
				font-size: 16px;
				color: #232323;;
				margin-bottom: 8px;
				width: calc(100% - 50px);
			}
			.info-con{
				color: #666666;
			}
			.state1{
				position: absolute;
				right: 5px;
				width: 20px;
				display: flex;
				align-items: center;
				// writing-mode:vertical-lr;
				font-weight: 700;
				color: #17AD84;
				height: 100%;
				border-left: 2px solid #F6F6F6;
				padding-left: 15px;
			}
			.state2{
				position: absolute;
				width: 27%;
				right: 0px;
			}
			.coupon-slot{
				position: absolute;
				right: 15px;
			}
		}
	}
	
	.instr{
		font-size: 14px;
		color: #666666;;
		margin-top: 10px;
		float: left;
		padding-left: 10px;
		padding-bottom: 10px;
		box-sizing: border-box;
	}
	
	//已使用
	.not-use{
		.coupon-left{
			opacity: 0.5;
		}
		.coupon-right{
			opacity: 0.5;
		}
	}
</style>