var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "goods_detail"
  }, [_c('van-swipe', {
    staticClass: "my-swipe",
    attrs: {
      "indicator-color": "white"
    }
  }, _vm._l(_vm.data.banner, function (item, index) {
    return _c('van-swipe-item', {
      key: index
    }, [index == 0 && item.video_url ? _c('video', {
      staticClass: "video-js",
      attrs: {
        "id": "myVideo",
        "x5-video-player-type": "h5",
        "playsinline": true,
        "x5-video-player-fullscreen": false,
        "poster": item.video_cover || _vm.data.banner[1]
      },
      on: {
        "canplay": function ($event) {
          return _vm.initVideo();
        }
      }
    }, [_c('source', {
      attrs: {
        "src": item.video_url,
        "type": "video/mp4"
      }
    })]) : _c('van-image', {
      attrs: {
        "src": item,
        "fit": "fill"
      },
      on: {
        "load": _vm.initHeight
      }
    })], 1);
  }), 1), _vm.status == 0 ? _c('div', {
    staticClass: "detail-info"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "money"
  }, [_c('div', {
    staticStyle: {
      "font-weight": "700"
    }
  }, [_c('span', [_vm._v("¥"), _c('span', {
    staticStyle: {
      "font-size": "30px"
    }
  }, [_vm._v(_vm._s(_vm.data.goods[0].price && _vm.data.goods[0].price.split(".")[0]))]), _vm._v("."), _c('span', [_vm._v(_vm._s(_vm.data.goods[0].price && _vm.data.goods[0].price.split(".")[1]))])])]), _c('div', [_vm._v("¥" + _vm._s(_vm.data.goods[0].price_show))])]), _c('div', {
    staticClass: "preson"
  }, [_c('van-icon', {
    attrs: {
      "name": "friends-o",
      "size": "18",
      "color": "#BFBFBF"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.data.goods[0].buy_num) + "人已抢")])], 1)]), _c('div', {
    staticClass: "bootom"
  }, [_vm._v(_vm._s(_vm.data.goods[0].title))])]) : _vm._e(), _vm.status == 2 ? _c('div', {
    staticClass: "time-info"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('span', [_vm._v("抢购价")]), _c('strong', [_vm._v("￥" + _vm._s(_vm.data.goods[0].price_pin))])]), _c('div', {
    staticClass: "bottom"
  }, [_c('em', [_vm._v("￥" + _vm._s(_vm.data.goods[0].price))]), _c('span', [_vm._v(_vm._s(_vm.data.goods[0].buy_num) + "人已抢")])])]), _vm.isTimeOut > 0 ? _c('div', {
    staticClass: "right"
  }, [_c('p', [_vm._v("限时秒杀")]), _c('van-count-down', {
    attrs: {
      "time": _vm._f("timer")(_vm.data.goods[0].pin_end_time),
      "format": "DD:HH:mm:ss"
    }
  })], 1) : _c('div', {
    staticClass: "right"
  }, [_c('p', [_vm._v("活动已结束")])])]) : _vm._e(), _vm.status == 1 ? _c('div', {
    staticClass: "time-info"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('span', [_vm._v("抢购价")]), _c('strong', [_vm._v("￥" + _vm._s(_vm.data.goods[0].price_pin))])]), _c('div', {
    staticClass: "bottom"
  }, [_c('em', [_vm._v("￥" + _vm._s(_vm.data.goods[0].price))]), _c('span', [_vm._v("累计已拼" + _vm._s(_vm.data.goods[0].buy_num) + "件")])])]), _vm.isTimeOut > 0 ? _c('div', {
    staticClass: "right"
  }, [_c('p', [_vm._v("限时秒杀")]), _c('van-count-down', {
    attrs: {
      "time": _vm._f("timer")(_vm.data.goods[0].pin_end_time),
      "format": "DD:HH:mm:ss"
    }
  })], 1) : _c('div', {
    staticClass: "right"
  }, [_c('p', [_vm._v("活动已结束")])])]) : _vm._e(), _c('van-cell', {
    on: {
      "click": _vm.openSelect
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('span', {
          staticStyle: {
            "margin-right": "20px",
            "white-space": "nowrap",
            "color": "#999"
          }
        }, [_vm._v("已选套餐")]), _c('div', {
          staticClass: "van-ellipsis",
          staticStyle: {
            "color": "#232323"
          }
        }, [_vm._v(_vm._s(_vm.selectPackge.short_title))])];
      },
      proxy: true
    }, {
      key: "right-icon",
      fn: function () {
        return [_c('van-icon', {
          staticClass: "search-icon",
          attrs: {
            "name": "ellipsis"
          }
        })];
      },
      proxy: true
    }])
  }), _vm.data.coupon.length > 0 ? _c('van-cell', {
    on: {
      "click": _vm.openCoupon
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('span', {
          staticStyle: {
            "margin-right": "20px",
            "white-space": "nowrap",
            "color": "#999"
          }
        }, [_vm._v("优惠")]), _c('div', {
          staticClass: "coupon-info",
          staticStyle: {
            "color": "#232323"
          }
        }, [_c('div', {
          staticClass: "info1"
        }, [_vm._v("领劵")]), _c('div', {
          staticClass: "info2"
        }, [_vm.data.coupon[0].sale_off_type == 2 ? _c('span', [parseInt(_vm.data.coupon[0].used_condition) == 0 ? _c('span', [_vm._v(" 无门槛减" + _vm._s(_vm.data.coupon[0].sale_off) + "元 ")]) : _c('span', [_vm._v(" 满" + _vm._s(parseInt(_vm.data.coupon[0].used_condition)) + "元可减" + _vm._s(_vm.data.coupon[0].sale_off) + "元 ")])]) : _vm._e(), _vm.data.coupon[0].sale_off_type == 1 ? _c('span', [parseInt(_vm.data.coupon[0].used_condition) == 0 ? _c('span', [_vm._v(" 无门槛" + _vm._s(_vm.data.coupon[0].sale_off) + "折 ")]) : _c('span', [_vm._v(" 满" + _vm._s(parseInt(_vm.data.coupon[0].used_condition)) + "元可" + _vm._s(_vm.data.coupon[0].sale_off) + "折 ")])]) : _vm._e()])])];
      },
      proxy: true
    }, {
      key: "right-icon",
      fn: function () {
        return [_c('van-icon', {
          staticClass: "search-left",
          attrs: {
            "name": "arrow"
          }
        })];
      },
      proxy: true
    }], null, false, 1376349734)
  }) : _vm._e(), _c('van-tabs', {
    staticStyle: {
      "padding-bottom": "90px"
    },
    attrs: {
      "scrollspy": "",
      "sticky": "",
      "line-width": "60",
      "title-inactive-color": "#999",
      "title-active-color": "#353334",
      "color": "#EC2627"
    },
    model: {
      value: _vm.active,
      callback: function ($$v) {
        _vm.active = $$v;
      },
      expression: "active"
    }
  }, [_c('van-tab', {
    attrs: {
      "title": "产品介绍"
    }
  }, [_c('div', {
    staticClass: "instru"
  }, [_c('div', {
    staticClass: "instru-con",
    domProps: {
      "innerHTML": _vm._s(_vm.data.goods[0].detail)
    }
  })])]), _c('van-tab', {
    attrs: {
      "title": "使用规则"
    }
  }, [_c('div', {
    staticClass: "instru"
  }, [_c('div', {
    staticClass: "instru-title"
  }, [_vm._v(" 使用规则 ")]), _c('div', {
    staticClass: "instru-con",
    domProps: {
      "innerHTML": _vm._s(_vm.data.goods[0].used_rules)
    }
  })])]), _c('van-tab', {
    attrs: {
      "title": "适用人群"
    }
  }, [_c('div', {
    staticClass: "instru"
  }, [_c('div', {
    staticClass: "instru-title"
  }, [_vm._v(" 适用人群 ")]), _c('div', {
    staticClass: "instru-con",
    domProps: {
      "innerHTML": _vm._s(_vm.data.goods[0].buy_rules)
    }
  })]), _c('div', {
    staticClass: "instru"
  }, [_c('div', {
    staticClass: "instru-title"
  }, [_vm._v(" 套餐亮点 ")]), _c('div', {
    staticClass: "instru-con",
    domProps: {
      "innerHTML": _vm._s(_vm.data.goods[0].check_items)
    }
  })]), _c('div', {
    staticClass: "instru"
  }, [_c('div', {
    staticClass: "instru-title"
  }, [_vm._v(" 温馨提示 ")]), _c('div', {
    staticClass: "instru-con",
    domProps: {
      "innerHTML": _vm._s(_vm.data.goods[0].tips)
    }
  })])]), _c('van-tab', {
    attrs: {
      "title": "商品评价"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" 商品评价 ("), _c('span', {
          staticStyle: {
            "color": "red"
          }
        }, [_vm._v(_vm._s(_vm.data.goods[0].comment_num))]), _vm._v(") ")];
      },
      proxy: true
    }])
  }, [_c('van-cell', {
    attrs: {
      "value": "查看更多",
      "title": "商品评价",
      "is-link": ""
    },
    on: {
      "click": function ($event) {
        return _vm.more();
      }
    }
  }), _vm.commentList.length > 0 ? _c('Comments', {
    attrs: {
      "list": _vm.commentList
    }
  }) : _c('van-empty', {
    attrs: {
      "description": "暂无评论"
    }
  })], 1)], 1), _vm.status == 2 && _vm.isTimeOut > 0 ? _c('van-notice-bar', {
    staticClass: "notice",
    attrs: {
      "left-icon": "volume-o"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('span', {
    staticStyle: {
      "margin-right": "8px"
    }
  }, [_vm._v("限时抢购，还剩")]), _c('van-count-down', {
    staticStyle: {
      "color": "#ed6a0c"
    },
    attrs: {
      "time": _vm._f("timer")(_vm.data.goods[0].pin_end_time),
      "format": "DD:HH:mm:ss"
    }
  })], 1)]) : _vm._e(), _vm.status == 1 && _vm.data.goods_pin.length > 0 && _vm.isTimeOut > 0 ? _c('van-notice-bar', {
    staticClass: "notice",
    attrs: {
      "left-icon": "volume-o",
      "scrollable": false
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "position": "relative"
    }
  }, [_vm.data.goods_pin.length > 0 ? _c('van-image', {
    attrs: {
      "round": "",
      "width": "17px",
      "height": "17px",
      "src": _vm.data.goods_pin[0].pin_nfo[0].users_headimgurl
    }
  }) : _vm._e(), _c('span', {
    staticStyle: {
      "margin": "0px 8px"
    }
  }, [_vm._v(" 还差 " + _vm._s(_vm.data.goods_pin.length > 0 ? _vm.data.goods_pin[0].join_num : _vm.data.goods[0].pin_num) + " 人拼单成功 ")]), _c('van-count-down', {
    staticStyle: {
      "color": "#ed6a0c"
    },
    attrs: {
      "time": _vm._f("timer")(_vm.data.goods[0].pin_end_time),
      "format": "DD:HH:mm:ss"
    }
  }), _c('van-button', {
    staticStyle: {
      "position": "absolute",
      "right": "10px"
    },
    attrs: {
      "type": "danger",
      "size": "mini",
      "round": ""
    },
    on: {
      "click": _vm.openSpellList
    }
  }, [_vm._v(" 去拼单 ")])], 1)]) : _vm._e(), _c('van-goods-action', [_c('van-goods-action-icon', {
    attrs: {
      "icon": "home-o",
      "text": "首页"
    },
    on: {
      "click": _vm.backHome
    }
  }), _c('van-goods-action-icon', {
    attrs: {
      "icon": "chat-o",
      "text": "客服"
    },
    on: {
      "click": _vm.openKefu
    }
  }), _c('van-goods-action-icon', {
    attrs: {
      "icon": "coupon-o",
      "text": "海报"
    },
    on: {
      "click": _vm.openPoster
    }
  }), _vm.status == 0 ? _c('van-goods-action-button', {
    attrs: {
      "type": "danger",
      "text": "立即预定"
    },
    on: {
      "click": function ($event) {
        return _vm.openSelect();
      }
    }
  }) : _vm._e(), _vm.status == 2 ? _c('van-goods-action-button', {
    attrs: {
      "type": "danger",
      "text": "立即抢购",
      "disabled": _vm.isTimeOut <= 0
    },
    on: {
      "click": function ($event) {
        return _vm.oneBackOrder(1);
      }
    }
  }) : _vm._e(), _vm.status == 1 ? _c('van-goods-action-button', {
    attrs: {
      "type": "warning",
      "disabled": _vm.isTimeOut <= 0
    },
    on: {
      "click": function ($event) {
        return _vm.openSelect();
      }
    }
  }, [_c('p', [_vm._v("￥" + _vm._s(_vm.data.goods[0].price))]), _vm._v(" 单独购买 ")]) : _vm._e(), _vm.status == 1 ? _c('van-goods-action-button', {
    attrs: {
      "type": "danger",
      "disabled": _vm.isTimeOut <= 0
    },
    on: {
      "click": function ($event) {
        return _vm.oneBackOrder(2);
      }
    }
  }, [_c('p', [_vm._v("￥" + _vm._s(_vm.data.goods[0].price_pin))]), _vm._v(" 发起拼单 ")]) : _vm._e()], 1), _c('ServicePopup', {
    attrs: {
      "kf_qrcode": _vm.data.goods[0].kf_info.kf_qrcode
    },
    model: {
      value: _vm.kefuShow,
      callback: function ($$v) {
        _vm.kefuShow = $$v;
      },
      expression: "kefuShow"
    }
  }), _c('van-popup', {
    staticClass: "selectPopup",
    attrs: {
      "position": "bottom",
      "closeable": "",
      "round": ""
    },
    on: {
      "close": _vm.cloneShow,
      "click-close-icon": _vm.cloneShow
    },
    model: {
      value: _vm.selectShow,
      callback: function ($$v) {
        _vm.selectShow = $$v;
      },
      expression: "selectShow"
    }
  }, [_c('div', {
    staticClass: "select-package"
  }, [_c('van-card', {
    attrs: {
      "price": _vm.selectPackge.price,
      "desc": _vm.selectPackge.check_items,
      "thumb": _vm.selectPackge.pic_url
    }
  }), _c('div', {
    staticClass: "bottom"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("套餐")]), _c('div', {
    staticClass: "select"
  }, _vm._l(_vm.data.goods, function (item) {
    return _c('div', {
      key: item.id,
      class: {
        'active': item.id == _vm.selectPackge.id,
        'item': true,
        'van-ellipsis': true
      },
      on: {
        "click": function ($event) {
          return _vm.selectOtherPackges(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.short_title ? item.short_title : '暂无标题') + " ")]);
  }), 0), _c('van-submit-bar', {
    attrs: {
      "button-text": "立即预定",
      "button-type": "info"
    },
    on: {
      "submit": _vm.backOrder
    }
  })], 1)], 1)]), _vm.status == 1 ? _c('van-popup', {
    staticClass: "spell-list",
    attrs: {
      "position": "bottom",
      "closeable": "",
      "round": ""
    },
    model: {
      value: _vm.spellList,
      callback: function ($$v) {
        _vm.spellList = $$v;
      },
      expression: "spellList"
    }
  }, [_vm.data.goods_pin.length > 0 ? _c('div', {
    staticClass: "text",
    staticStyle: {
      "margin-bottom": "15px"
    }
  }, [_vm._v(" 参与" + _vm._s(_vm.data.goods_pin[0].pin_nfo[0].users_name) + "的拼单 ")]) : _c('div', {
    staticClass: "text",
    staticStyle: {
      "margin-bottom": "15px"
    }
  }, [_vm._v(" 发起拼单 ")]), _c('div', {
    staticClass: "text"
  }, [_vm._v(" 还剩 " + _vm._s(_vm.data.goods_pin.length > 0 ? _vm.data.goods_pin[0].join_num : _vm.data.goods[0].pin_num) + " 个名额 ")]), _c('van-count-down', {
    attrs: {
      "time": _vm._f("timer")(_vm.data.goods[0].pin_end_time),
      "format": "DD:HH:mm:ss"
    }
  }), _c('div', {
    staticClass: "img-list"
  }, [_vm._l(_vm.data.goods_pin[0].pin_nfo.slice(0, 4), function (item) {
    return _c('van-image', {
      key: item.users_id,
      staticClass: "head-img",
      attrs: {
        "round": "",
        "width": "60px",
        "height": "60px",
        "src": item.users_headimgurl,
        "cover": ""
      }
    });
  }), _c('div', {
    staticClass: "add-head",
    on: {
      "click": _vm.groupOrder
    }
  }, [_c('van-icon', {
    attrs: {
      "name": "plus"
    }
  })], 1)], 2), _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center"
    }
  }, [_c('van-button', {
    attrs: {
      "type": "danger",
      "round": "",
      "size": "small"
    },
    on: {
      "click": _vm.groupOrder
    }
  }, [_vm._v("参与拼单")])], 1)], 1) : _vm._e(), _c('van-popup', {
    staticClass: "coupon-popup",
    style: {
      height: '70%'
    },
    attrs: {
      "position": "bottom",
      "closeable": "",
      "round": ""
    },
    model: {
      value: _vm.coupon,
      callback: function ($$v) {
        _vm.coupon = $$v;
      },
      expression: "coupon"
    }
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("优惠")]), _c('div', {
    staticClass: "info"
  }, [_vm._v("可领取优惠券")]), _c('div', {
    staticClass: "coupon-list"
  }, _vm._l(_vm.data.coupon, function (item, index) {
    return _c('Coupon', {
      key: index,
      attrs: {
        "data": item,
        "state": item.is_rec == 0 ? 1 : 4,
        "opacity": item.is_rec == 1
      },
      on: {
        "click": function ($event) {
          return _vm.getCoupons(item);
        }
      }
    });
  }), 1)]), _vm.showPoster ? _c('posters', {
    attrs: {
      "poster": _vm.posters
    },
    on: {
      "downPosters": _vm.downPosters
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };